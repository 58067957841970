<template>
  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="var(--text-color)"
          >
            <path
              d="M7.97496 4.94165L2.91663 9.99998L7.97496 15.0583"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.0834 10H3.05835"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </section>

    <section class="p-2 terms container_wrapper">
      <div class="container">
        <h5>
          <strong> {{ $t("termsAndConditions") }} </strong>
        </h5>

        <h6>
          <strong> 1. {{ $t("general") }}</strong>
        </h6>
        <p>1.1 {{ $t("termsAndConditionsTogether") }}</p>
        <p>1.2 {{ $t("operatingLicense") }}</p>
        <p>1.3 {{ $t("tAndCCommencement") }}</p>
        <p>1.4 {{ $t("gameRules") }}</p>
        <p>1.5 {{ $t("dateAndTimeReference") }}</p>
        <p>1.6 {{ $t("jurisdictionRestrictions") }}</p>
        <p>1.7 {{ $t("responsibilityToVerifyJurisdiction") }}</p>
        <p>1.8 {{ $t("preventRestrictedJurisdictions") }}</p>
        <p>1.9 {{ $t("termsAmendment") }}</p>

        <h6>
          <strong> 2. {{ $t("accountRegistration") }}</strong>
        </h6>
        <p>2.1 {{ $t("accountRegistrationRequirement") }}</p>
        <p>2.2 {{ $t("singleAccountRestriction") }}</p>
        <p>2.3 {{ $t("accountOpeningDiscretion") }}</p>
        <p>2.4 {{ $t("accountCreationRepresentation") }}</p>
        <ul>
          <p>{{ $t("termsAcknowledgement") }}</p>

          <li>{{ $t("ageRequirement") }}</li>
          <li>{{ $t("jurisdictionRestriction") }}</li>
          <li>{{ $t("legalCompliance") }}</li>
          <li>{{ $t("personalUseOnly") }}</li>
          <li>{{ $t("informedDecisions") }}</li>
          <li>{{ $t("noThirdPartyUse") }}</li>
          <li>{{ $t("legalFunds") }}</li>
          <li>{{ $t("fairPlay") }}</li>
          <li>{{ $t("accountSecurity") }}</li>
        </ul>
        <p>{{ $t("accountSecurityPurpose") }}</p>

        <p>2.5 {{ $t("2.5") }}</p>
        <h6>3.0 {{ $t("identificationAndVerification") }}</h6>
        <p>3.1 {{ $t("compliancePurpose") }}</p>
        <p>3.1 {{ $t("informationProcessing") }}</p>
        <p>3.2 {{ $t("documentProvision") }}</p>
        <p>3.3 {{ $t("documents") }}</p>
        <ul>
          <li>{{ $t("documents.identification") }}</li>
          <li>{{ $t("documents.residence_verification") }}</li>
          <li>{{ $t("documents.source_of_funds") }}</li>
          <li>{{ $t("documents.wealth_verification") }}</li>
          <li>{{ $t("documents.tax_id") }}</li>
          <li>{{ $t("documents.contact_information") }}</li>
        </ul>
        <p>3.4 {{ $t("verification_policy.documents") }}</p>
        <p>3.5 {{ $t("verification_policy.provided") }}</p>
        <p>3.6 {{ $t("verification_policy.reserve_right") }}</p>
        <p>3.7 {{ $t("verification_policy.additional_doc") }}</p>
        <p>{{ $t("verification_policy.when_verification_commenced") }}</p>
        <p>3.8 {{ $t("verification_policy.commenced") }}</p>

        <h6>
          <strong> 4. {{ $t("currency_conversions_rounding") }}</strong>
        </h6>
        <p>4.1 {{ $t("operational_currency") }}</p>
        <p>4.2 {{ $t("multiple_currency_accounts") }}</p>
        <p>4.3 {{ $t("payment_transactions") }}</p>
        <p>4.4 {{ $t("rounding_policy") }}</p>
        <h6>
          <strong> 5. {{ $t("games_participation_title") }}</strong>
        </h6>
        <p>5.1 {{ $t("games_participation") }}</p>
        <p>5.2 {{ $t("no_warranties") }}</p>
        <p>5.3 {{ $t("placing_bet") }}</p>
        <p>5.4 {{ $t("application_approval") }}</p>
        <p>5.5 {{ $t("communication_errors") }}</p>
        <p>5.6 {{ $t("monetary_award") }}</p>
        <p>5.7 {{ $t("win_calculation") }}</p>
        <p>5.8 {{ $t("acknowledgment") }}</p>
        <p>5.9 {{ $t("minimum_bet") }}</p>
        <p>{{ $t("maximum_odds_parlays") }}</p>
        <p>{{ $t("maximum_bet_amount") }}</p>
        <p>{{ $t("maximum_payout") }}</p>
        <p>5.10 {{ $t("maximum_payout_avoidance") }}</p>

        <h6>
          <strong> 6. {{ $t("TOP_UPs_and_Withdrawals") }}</strong>
        </h6>
        <p>6.1 {{ $t("deposit_requirements") }}</p>
        <p>6.2 {{ $t("payment_methods") }}</p>
        <p>6.3 {{ $t("transfer_policy") }}</p>
        <p>6.4 {{ $t("account_status") }}</p>
        <p>6.5 {{ $t("lawful_source") }}</p>
        <p>6.6 {{ $t("deposit_confirmation") }}</p>
        <p>6.7 {{ $t("withdrawal_process") }}</p>
        <p>6.8 {{ $t("withdrawal_restrictions") }}</p>
        <p>6.9 {{ $t("withdrawal_requests") }}</p>
        <p>6.10 {{ $t("transaction_processing") }}</p>
        <ul>
          <li>{{ $t("transaction_processings.1") }}</li>
          <li>{{ $t("transaction_processings.2") }}</li>
          <li>{{ $t("transaction_processings.3") }}</li>
          <li>{{ $t("transaction_processings.4") }}</li>
          <li>{{ $t("transaction_processings.5") }}</li>
        </ul>
        <p>6.11 {{ $t("We_reserve_right") }}</p>
        <ul>
          <li>{{ $t("We_reserve_right_points.1") }}</li>
          <li>{{ $t("We_reserve_right_points.2") }}</li>
          <li>{{ $t("We_reserve_right_points.3") }}</li>
          <li>{{ $t("We_reserve_right_points.4") }}</li>
          <li>{{ $t("We_reserve_right_points.5") }}</li>
        </ul>

        <p>6.12 {{ $t("liability_clause") }}</p>
        <p>6.13 {{ $t("withdrawal_limits") }}</p>
        <p>{{ $t("withdrawal_limits_max") }}</p>
        <p>6.14 {{ $t("daily_withdrawal_limit") }}</p>
        <p>6.15 {{ $t("payment_service_provider_limits") }}</p>
        <p>6.16 {{ $t("transfer_prohibition") }}</p>
        <p>6.17 {{ $t("payment_agents") }}</p>

        <h6>7 {{ $t("wage_requirements") }}</h6>
        <p>7.1 {{ $t("permitted_purpose") }}</p>
        <p>7. 1. 1 {{ $t("wagering_requirements.general") }}</p>
        <p>
          7. 1. 2 {{ $t("wagering_requirements.participation_requirement") }}
        </p>
        <p>7. 1. 3 {{ $t("wagering_requirements.new_deposit_requirement") }}</p>
        <p>
          7. 1. 4
          {{ $t("wagering_requirements.new_deposit_before_fulfillment") }}
        </p>
        <p>7. 1. 5 {{ $t("wagering_requirements.wins_inclusion") }}</p>
        <p>7. 1. 6 {{ $t("wagering_requirements.wins_inclusion_purpose") }}</p>
        <ul>
          <li>
            {{
              $t(
                "wagering_requirements.wins_inclusion_purpose.wins_inclusion_purpose_point.1"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "wagering_requirements.wins_inclusion_purpose.wins_inclusion_purpose_point.2"
              )
            }}
          </li>
        </ul>
        <p>7.2 {{ $t("wagering_requirements.additional_wagering") }}</p>

        <h6>8 {{ $t("Fees_and_costs") }}</h6>
        <p>8.1 {{ $t("Fees_and_costs_general_rule") }}</p>

        <h6>9 {{ $t("refunds") }}</h6>

        <p>9.1 {{ $t("refund_policies.refund_requests") }}</p>
        <p>9.2 {{ $t("refund_policies.bonus_cancellation") }}</p>
        <p>9.3 {{ $t("refund_policies.security_risk_refunds") }}</p>
        <p>9.4 {{ $t("refund_policies.refund_costs") }}</p>
        <h6>10 {{ $t("dormant_account") }}</h6>

        <p>10.1 {{ $t("dormant_account_purpose") }}</p>
        <p>10.2 {{ $t("bonus_notification") }}</p>
        <p>10.3 {{ $t("bonus_cancellation") }}</p>
        <p>10.4 {{ $t("dormant_reason") }}</p>
        <ul>
          <li>{{ $t("dormant_reason1") }}</li>
          <li>{{ $t("dormant_reason2") }}</li>
        </ul>
        <p>10.5 {{ $t("close_dormart_account") }}</p>

        <h6>11 {{ $t("antiFraud") }}</h6>
        <p>11.1 {{ $t("anti_fraud_principles_description") }}</p>
        <ul>
          <li>
            {{ $t("prohibited_activities.collusion") }}
          </li>
          <li>
            {{ $t("prohibited_activities.unfair_strategies") }}
          </li>
          <li>
            {{ $t("prohibited_activities.cooperative_play") }}
          </li>
          <li>
            {{ $t("prohibited_activities.fraudulent_actions") }}
          </li>
          <li>
            {{ $t("prohibited_activities.misuse_of_funds") }}
          </li>
          <li>
            {{ $t("prohibited_activities.chargeback_transactions") }}
          </li>
          <li>
            {{ $t("prohibited_activities.deposit_reverse_requests") }}
          </li>
          <li>
            {{ $t("prohibited_activities.multiple_accounts") }}
          </li>
          <li>
            {{ $t("prohibited_activities.shared_accounts") }}
          </li>
          <li>
            {{ $t("prohibited_activities.vpn_usage") }}
          </li>
          <li>
            {{ $t("prohibited_activities.misleading_information") }}
          </li>
          <li>
            {{ $t("prohibited_activities.exploitation_of_bonuses") }}
          </li>
          <li>
            {{ $t("prohibited_activities.low_risk_play") }}
          </li>
          <li>
            {{ $t("prohibited_activities.hedge_betting") }}
          </li>
          <li>
            {{ $t("prohibited_activities.high_single_bets") }}
          </li>
          <li>
            {{ $t("prohibited_activities.influencing_game_results") }}
          </li>
          <li>
            {{ $t("prohibited_activities.advantage_gambling") }}
          </li>
          <li>
            {{ $t("prohibited_activities.cheating") }}
          </li>
          <li>
            {{ $t("prohibited_activities.money_laundering_suspicions") }}
          </li>
          <li>
            {{ $t("prohibited_activities.irregular_transactions") }}
          </li>
        </ul>

        <p>11.2 {{ $t("fairPlayPrinciplesDescription") }}</p>
        <p>11.3 {{ $t("withdrawalOfFraudulentFunds") }}</p>
        <p>11.4 {{ $t("behaviorReview") }}</p>
        <p>11.5 {{ $t("indemnification") }}</p>
        <p>11.6 {{ $t("reportingFraud") }}</p>
        <p>11.7 {{ $t("suspensionOfAccount") }}</p>
        <p>11.8 {{ $t("voidResults") }}</p>

        <h6>12 {{ $t("consequencesOfBreach") }}</h6>
        <p>12.1 {{ $t("consequencesOfBreach1") }}</p>
        <ul>
          <li>
            {{ $t("reversePayouts") }}
          </li>
          <li>
            {{ $t("ceaseGameProvision") }}
          </li>
          <li>
            {{ $t("suspendTransactions") }}
          </li>
          <li>
            {{ $t("imposeLimits") }}
          </li>
          <li>
            {{ $t("returnDisputableAmounts") }}
          </li>
          <li>
            {{ $t("blockAccount") }}
          </li>
          <li>
            {{ $t("closeAccount") }}
          </li>
          <li>
            {{ $t("imposeSanctions") }}
          </li>
        </ul>
        <p>12.2 {{ $t("consequencesOfBreach2") }}</p>

        <h6>13 {{ $t("closingAccount") }}</h6>
        <p>13.1 {{ $t("accountClosureUserRequest") }}</p>
        <p>13.2 {{ $t("ourDiscretion") }}</p>
        <p>13.3 {{ $t("withdrawalAfterClosure") }}</p>
        <p>13.4 {{ $t("breachConsequences") }}</p>

        <h6>14 {{ $t("taxation") }}</h6>
        <p>14.1 {{ $t("taxObligationsAcknowledgment") }}</p>
        <p>14.2 {{ $t("taxObligationsCompliance") }}</p>
        <p>14.3 {{ $t("taxObligationsCooperation") }}</p>
        <p>14.4 {{ $t("taxObligationsLiabilityRelease") }}</p>

        <h6>15 {{ $t("responsibleGaming") }}</h6>
        <p>15.1 {{ $t("entertainmentAcknowledgment") }}</p>
        <p>15.2 {{ $t("personalLimitsApplication") }}</p>
        <p>15.3 {{ $t("personalLimitsNotification") }}</p>
        <p>15.4 {{ $t("personalLimitAffect") }}</p>
        <p>15.5 {{ $t("personalLimitsstrictLimits") }}</p>
        <p>15.6 {{ $t("personalLimitsexpiry") }}</p>
        <p>15.7 {{ $t("personalLimitspolicyAcceptance") }}</p>

        <h6>16 {{ $t("privacyPolicy") }}</h6>
        <p>16.1 {{ $t("privacyPolicyTC") }}</p>

        <h6>17 {{ $t("errorAndOmission") }}</h6>
        <p>17.1 {{ $t("technicalIssuesCommitment") }}</p>
        <p>17.2 {{ $t("acknowledgmentOfTechnicalIssues") }}</p>
        <p>17.3 {{ $t("rightToCancelTransactions") }}</p>
        <p>17.4 {{ $t("rightToDeductDisputedAmount") }}</p>
        <p>17.5 {{ $t("debtFromWithdrawnFunds") }}</p>
        <p>17.6 {{ $t("rightToRequestRecovery") }}</p>
        <p>17.7 {{ $t("recoverySubjectToCompliance") }}</p>
        <p>17.8 {{ $t("exclusionOfThirdPartyIssues") }}</p>
        <p>17.9 {{ $t("definedRightTechnicalIssues") }}</p>

        <h6>18 {{ $t("limitationLiability") }}</h6>
        <p>18.1 {{ $t("acknowledgmentOfRisk") }}</p>
        <p>18.2 {{ $t("disclaimerOfWarranties") }}</p>
        <ul>
          <li>
            {{ $t("warrantyFitForPurpose") }}
          </li>
          <li>
            {{ $t("warrantyFreeFromErrors") }}
          </li>
          <li>
            {{ $t("warrantyAccessibility") }}
          </li>
          <li>
            {{ $t("liabilityDisclaimer") }}
          </li>
          <li>
            {{ $t("liabilityForceMajeure") }}
          </li>
        </ul>
        <p>18.3 {{ $t("indemnificationClause") }}</p>
        <p>18.4 {{ $t("maximumLiabilityClause") }}</p>
        <ul>
          <li>
            {{ $t("liabilityBetAmount") }}
          </li>
          <li>
            {{ $t("depositMisplacementAmount") }}
          </li>
        </ul>
        <p>18.5 {{ $t("obligationToPayWins") }}</p>
        <p>18.6 {{ $t("nonExcludableLiability") }}</p>

        <h6>19 {{ $t("disclaimerWarranties") }}</h6>
        <p>19.1 {{ $t("websiteAsIsDisclaimer") }}</p>
        <p>19.2 {{ $t("availabilityDisclaimer") }}</p>
        <p>19.3 {{ $t("responsibleGamingToolsDisclaimer") }}</p>
        <p>19.4 {{ $t("securityAndVirusDisclaimer") }}</p>
        <p>19.5 {{ $t("accuracyDisclaimer") }}</p>
        <p>19.6 {{ $t("externalSitesDisclaimer") }}</p>
        <p>19.7 {{ $t("deviceDamageDisclaimer") }}</p>
        <p>19.8 {{ $t("fundProtectionDisclaimer") }}</p>
        <p>19.9 {{ $t("insolvencyDisclaimer") }}</p>

        <h6>20 {{ $t("intellectualProperty") }}</h6>
        <p>20.1 {{ $t("brandOwnership") }}</p>
        <p>20.2 {{ $t("technologyOwnership") }}</p>
        <p>20.3 {{ $t("softwareUsageLimitation") }}</p>
        <p>20.4 {{ $t("linkingRestriction") }}</p>
        <p>20.5 {{ $t("contentMonitoringRestriction") }}</p>

        <h6>21 {{ $t("customerSupportComplaints") }}</h6>
        <p>21.1 {{ $t("customerSupportRequest") }}</p>
        <ul>
          <li>
            {{ $t("disputeEventGameResult") }}
          </li>
          <li>
            {{ $t("disputeEventAccountRelated") }}
          </li>
        </ul>
        <p>{{ $t("disputeEventAccountInfo") }}</p>
        <p>21.2 {{ $t("communicationRecordingMonitoring") }}</p>
        <p>21.3 {{ $t("abuseOfComplaintRights") }}</p>
        <p>21.4 {{ $t("disputeResolutionServerLogs") }}</p>
        <p>21.5 {{ $t("communicationDeemedDelivered") }}</p>

        <h6>22 {{ $t("waiver") }}</h6>

        <p>22.1 {{ $t("ourFailure") }}</p>
        <h6>23 {{ $t("Séparabilité") }}</h6>
        <p>23.1 {{ $t("severabilityText") }}</p>

        <h6>24 {{ $t("RelationshipThirdPartyRights") }}</h6>
        <p>24.1 {{ $t("noPartnershipOrAgency") }}</p>
        <p>24.2 {{ $t("noThirdPartyRights") }}</p>

        <h6>25 {{ $t("applicableurisdiction") }}</h6>
        <p>25.1 {{ $t("governingLawAndJurisdiction") }}</p>
        <p>25.2 {{ $t("gamesOrganizedLocation") }}</p>

        <h6>26 {{ $t("entireAgreementTitle") }}</h6>
        <p>26.1 {{ $t("entireAgreement") }}</p>

        <h6>27 {{ $t("assignment") }}</h6>
        <p>27.1 {{ $t("assignmentByCompany") }}</p>
        <p>27.2 {{ $t("noAssignmentByUser") }}</p>

        <h6>28 {{ $t("amendments") }}</h6>
        <p>28.1 {{ $t("rightToAmendTerms") }}</p>
        <p>28.2 {{ $t("agreementUponContinuedUse") }}</p>

        <h6>29 {{ $t("englishLanguageControls") }}</h6>
        <p>28.1 {{ $t("englishVersionPrevails") }}</p>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/Footer.vue";

export default {
  name: "terms",
  components: { Footer },
  mounted: function () {
    //
  },
  methods: {
    //
  },
};
</script>

<style src="./index.css" scoped></style>
